<template>

<div class="details">
 <div class="nav-bar">
   <img src="../assets/images/back.png" alt="" @click="jumpUrl('/home')"/>
   <span>{{$t('home.zcc')+$t('home.mx')}}</span>
    <div class="zk" @click="showPicker=true">
      {{columnObj[value].text}}
      <img src="../assets/images/zk.png" alt=""/>
    </div>
   <van-popup v-model:show="showPicker" round position="bottom">
     <van-picker
         v-model="value"
         :columns="columns"
         @cancel="showPicker = false"
         @confirm="onConfirm"
         :confirm-button-text="$t('home.qd')"
         :cancel-button-text="$t('home.qx')"
     />
   </van-popup>
 </div>
  <van-list
      v-if="list.length>0"
      v-model:loading="loading"
      :finished="finished"
      :finished-text="$t('home.noMore')"
      :loading-text="$t('home.loading')"
      :error-text="$t('home.failed')"
      @load="onLoad"
  >
   <div v-for="(item,i) in list" :key="i" class="list_info">
     <div class="left">
      <p>{{columnObj[item.type].text}}</p>
       {{$t('home.ye')}}：{{item.afterAmount}}
     </div>
     <div class="right">
       <strong v-if="item.amount<0" style="color: #FF5A5A">{{item.amount}}</strong>
       <strong v-else >{{'+'+item.amount}}</strong>
       {{locale==='en-US'?toHHmmss(item.createTime,-5):toHHmmss(item.createTime,+8)}}
     </div>
   </div>
  </van-list>
  <div class="no-data" v-else>
    <img src="../assets/images/no_data.png" alt=""/>
  </div>
</div>
</template>
<script setup>
import {onBeforeMount, ref,getCurrentInstance} from 'vue'
import { useRouter} from "vue-router";
import {getUserAssetsDetail} from "../api";
import {toHHmmss} from '../utils/decimalTool'
import {useI18n} from "vue-i18n";
const { $t } = getCurrentInstance().proxy;
// eslint-disable-next-line no-unused-vars
const {locale } = useI18n()
const router = useRouter()
const jumpUrl=(url)=>{
  router.push(url)
}

const showPicker=ref(false)
const value=ref(0)
const columns =ref([
  { text: $t('home.qb'), value: 0 },
  { text: $t('home.lxjl'), value: 1 },
  { text: $t('home.dthl'), value: 2 },
  { text: $t('home.vipjl'), value: 3 },
  { text: $t('home.yy'), value: 4 },
  { text: $t('home.cz'), value: 5 },
  { text: $t('home.tx'), value: 6 },
  { text: $t('home.txth'), value: 7 },
  { text: $t('home.thbj'), value: 8 },
  { text: $t('home.jslx'), value: 9 },
  { text: $t('home.jsbj'), value: 10 },
  // { text: $t('home.jjff'), value: 11 },
  { text: $t('home.sysljl'), value: 12 },
  // { text: $t('home.pjjl'), value: 13 },
  { text: $t('home.dr')+' USDT', value: 18 },
])


const columnObj=ref((function() {
  let obj = {};
  columns.value.forEach(v=>{
    obj[v["value"]] = v;
  })
  return obj;
})());

const onConfirm=(val)=>{
  value.value=val.value
  showPicker.value=false
  list.value=[]
  pageNo.value=1
  getHttp()
}
const pageNo=ref(1)
const total=ref(0)
const list = ref([]);
const loading = ref(false);
const finished = ref(false);


const getHttp=()=>{
  getUserAssetsDetail({
    "styleType": 1,    //1：所有钱包明细 2：“充值余额”字段明细
    "type":value.value, //记录类型，全部：0，其他按钱包记录变动类型依次传入
    "pageNo":pageNo.value,
    "pageSize":10,
  }).then(res=>{
    list.value.push(...res.data.logs)
    total.value=res.data.page.totalNum
  })
}
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getHttp()
      loading.value = false;

    }
  }, 1000);
};
onBeforeMount(()=>{
 getHttp()
})
</script>
<style scoped lang="less">
.details{
  padding: 0px 18px 18px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  box-sizing: border-box;
  .nav-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 52px;
    img{
      width: 16px;
      height: 15px;
      padding-right: 34px;
    }
    span{
      font-weight: bold;
      font-size: 17px;
      color: #FFFFFF;
    }
   .zk{
     font-weight: 500;
     font-size: 15px;
     color: #6FFEE8;
     display: flex;
     align-items: center;
     img{
       width: 11px;
       height: 6px;
       margin-left: 6px;
       padding: 0;
     }
   }
    /deep/.van-popup {
      background: #232A2E;
    }
    /deep/.van-picker{
      background: #232A2E;
    }
    /deep/.van-picker-column__item{
      color: #FFFFFF;
    }
    /deep/.van-picker__confirm{
      color: #6FFEE8;
    }

    /deep/.van-picker__mask{
      background: none;
    }
  }
  .list_info{
    border-bottom: 1px solid #7E8D95;
    padding: 19px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      text-align: left;

      p{
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
        margin: 0 0 10px 0;
        text-align: left;
      }
    }
    .right{
      display: flex;
      align-items: end;
      flex-direction: column;
      font-weight: 500;
      font-size: 12px;
      color: #7E8D95;
      strong{
        font-weight: bold;
        font-size: 20px;
        color: #6FFEE8;
        margin-bottom: 10px;
      }
    }
  }
}
.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  img{
    width: 176px;
    height: 258px;
  }
}
</style>
